.photo-spinner {
    margin: 40px 0;
    height: 520px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.photo-spinner-img {
    width: 400px;
}

@media (prefers-reduced-motion: no-preference) {
    .photo-spinner-img {
        animation: photo-spinner-spin infinite alternate 3s ease-in-out;
        margin: 80px 0;
    }
}

@keyframes photo-spinner-spin {
    to {
        transform: rotate(15deg);
    }
    from {
        transform: rotate(-15deg);
    }
}

@media only screen and (max-width: 600px) {
    .photo-spinner {
        margin: 0;
        height: 340px;
        cursor: none;
    }

    .photo-spinner-img {
        width: 200px;
    }
}
