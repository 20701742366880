.header-nav-bar {
    display: initial;
}

.header-drawer-toggle {
    display: none;
}

@media (max-width: 832px) {
    .header-nav-bar {
        display: none;
    }

    .header-drawer-toggle {
        display: initial;
    }
}
