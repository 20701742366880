@import url(https://fonts.googleapis.com/css?family=Bentham|Great+Vibes|Dancing+Script:wght@700&display=swap);
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
}

.photo-spinner {
    margin: 40px 0;
    height: 520px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.photo-spinner-img {
    width: 400px;
}

@media (prefers-reduced-motion: no-preference) {
    .photo-spinner-img {
        -webkit-animation: photo-spinner-spin infinite alternate 3s ease-in-out;
                animation: photo-spinner-spin infinite alternate 3s ease-in-out;
        margin: 80px 0;
    }
}

@-webkit-keyframes photo-spinner-spin {
    to {
        transform: rotate(15deg);
    }
    from {
        transform: rotate(-15deg);
    }
}

@keyframes photo-spinner-spin {
    to {
        transform: rotate(15deg);
    }
    from {
        transform: rotate(-15deg);
    }
}

@media only screen and (max-width: 600px) {
    .photo-spinner {
        margin: 0;
        height: 340px;
        cursor: none;
    }

    .photo-spinner-img {
        width: 200px;
    }
}

.beach-casual-video {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    width: 100%;
    max-width: 1000px;
}

.beach-casual-video iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

.header-nav-bar {
    display: inline;
    display: initial;
}

.header-drawer-toggle {
    display: none;
}

@media (max-width: 832px) {
    .header-nav-bar {
        display: none;
    }

    .header-drawer-toggle {
        display: inline;
        display: initial;
    }
}

.react-toggle {
    touch-action: pan-x;

    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.react-toggle--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    transition: opacity 0.25s;
}

.react-toggle-track {
    width: 50px;
    height: 24px;
    padding: 0;
    border-radius: 30px;
    background-color: #af8969;
    transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
    background-image: url('/toggle-checked-bg.png');
    opacity: 0.9;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-image: url('/toggle-checked-bg.png');
    opacity: 1;
}

.react-toggle-track-check {
    position: absolute;
    width: 14px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: 8px;
    opacity: 0;
    transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
    opacity: 1;
    transition: opacity 0.25s ease;
}

.react-toggle-track-x {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    right: 10px;
    opacity: 1;
    transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
    opacity: 0;
}

.react-toggle-thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 22px;
    height: 22px;
    border: 1px solid #4d4d4d;
    border-radius: 50%;
    background-color: #fafafa;
    box-sizing: border-box;
    transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
    left: 27px;
    border-color: #19ab27;
}

.react-toggle--focus .react-toggle-thumb {
    box-shadow: 0px 0px 2px 3px #0099e0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    box-shadow: 0px 0px 5px 5px #0099e0;
}

